<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list='["visit_total","signboard","shelves","materials","freezing","box","not_freezing"]'
            ref="list"
			:is_page="false"
			rowKey="id"
            :submit_preprocessing="submit_preprocessing"
			@expandedRowsChange="expandedRowsChange($event)"
            @list_after="list_after"
        >

			<template slot="right_btn">
				<a v-if="this.$store.state.page_auth.export" @click="report()">
					<a-button class="a_btn">导出</a-button>
				</a>
            </template>

			<template slot="visit_total" slot-scope="record">
				<a @click="see(record,'visit_total')">{{record.text}}</a>
			</template>
			<template slot="signboard" slot-scope="record">
				<a @click="see(record,'signboard')">{{record.text}}</a>
			</template>
			<template slot="shelves" slot-scope="record">
				<a @click="see(record,'shelves')">{{record.text}}</a>
			</template>
			<template slot="materials" slot-scope="record">
				<a @click="see(record,'materials')">{{record.text}}</a>
			</template>
			<template slot="freezing" slot-scope="record">
				<a @click="see(record,'freezing')">{{record.text}}</a>
			</template>
			<template slot="box" slot-scope="record">
				<a @click="see(record,'box')">{{record.text}}</a>
			</template>
			<template slot="not_freezing" slot-scope="record">
				<a @click="see(record,'not_freezing')">{{record.text}}</a>
			</template>
			
        </TableList>

    </div>
</template>

<script>

import TableList from "@/components/TableList";
import { userPhoto ,userPhotoSon} from "@/api/customer";

const columns = [
	{
        title: "名称",
        dataIndex: "name",
    },
    {
        title: "拜访次数",
		dataIndex: "visit_total",
		scopedSlots: {
            customRender: "visit_total",
        }
    },
	
    {
        title: "店招",
		dataIndex: "signboard",
		scopedSlots: {
            customRender: "signboard",
        }
    },
    {
        title: "货架陈列",
		dataIndex: "shelves",
		scopedSlots: {
            customRender: "shelves",
        }
    },
    {
        title: "广宣物料堆箱",
		dataIndex: "materials",
		scopedSlots: {
            customRender: "materials",
        }
    },
	{
        title: "冰冻化",
		dataIndex: "freezing",
		scopedSlots: {
            customRender: "freezing",
        }
	},
	{
        title: "堆箱",
		dataIndex: "box",
		scopedSlots: {
            customRender: "box",
        }
    },
	{
        title: "冰冻化未拍照",
		dataIndex: "not_freezing",
		scopedSlots: {
            customRender: "not_freezing",
        }
    },
	{
        title: "冰冻化上传率",
        dataIndex: "freezing_ratio",
    }
];

export default {
    name: "Index",
    components: {
		TableList,
    },
    data() {
        return {
            get_table_list: userPhoto,
            submit_preprocessing: {
                array_to_string: ['department','role_id']
            },
            order_details: {
                goods: [],
            },
            columns,
            form_data_seo: {
                list: [
					{
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
					},
					{
                        type: "tree-select",
                        name: "role_id",
                        title: "岗位职务",
                        options: {},
                        treeData: [],
                        multiple: true
					},
					// {
                    //     type: "select",
                    //     name: "role_id",
                    //     title: "岗位职务",
                    //     mode: "default",
                    //     options: {},
                    //     list: []
                    // },
                    {
                        type: "text",
                        name: "username",
                        title: "姓名",
                        placeholder: '姓名/账号/工号/手机号',
                        mode: "default",
                        options: {},
                        list: []
					},
					{
						type: "select",
						name: "user_status",
						title: "在职状态",
						mode: "default",
						options: {},
						list: this.$config.user_status_list
					},
					{
                        type: "range_date",
                        name: "range_date",
                        title: "时间",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
                ...this.$config.form_data_seo
            },
            form_data: {}
        };
    },
    async created() {
        this.$method.get_department().then(res => {
			this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list,'department','treeData',res)
		});
		
		// this.$method.get_role().then(res => {
        //     this.form_data_seo.list.forEach(item => {
        //         if (item.name == "role_id") {
        //             item.list = [...this.$config.status_all, ...res];
        //         }
        //     });
		// });
        this.$method.get_role_tree().then(res => {
			this.form_data_seo.list.forEach(item => {
				if (item.name == "role_id") {
					item.treeData = res;
				}
			});
		});
    },
    methods: {
		see({record},type){
			
			this.$router.push({
				path:"/imitation/index",
				query:{
					[record.type == 2 ? 'department' : 'uid']:record.id,
					data_type:type,
					visit_date:record.visit_time,
					...this.$refs.list.seo_data,
				}
			});
		},
		report() {
			this.$router.push({
				path:"/report/modify_report",
				query:{
					report_key:"custom_visit_user",
					...this.$refs.list.seo_data
				}
			});
		},
		get_list_key(list, key, index_arr = []) {
            for (let i = 0; i < list.length; i++) {
                if (list[i].id == key) {
                    return list[i]
                }

                if (Array.isArray(list[i].children) && list[i].children.length) {
                    let value = this.get_list_key(list[i].children, key);
                    if (value !== false) {
                        return value;
                    }
                }
            }

            return false;
        },
		expandedRowsChange(e) {
            e.forEach(key => {
                let list = JSON.parse(JSON.stringify(this.$refs.list.list));

                let item = this.get_list_key(list, key);

                if (!item.hasOwnProperty('is_req')) {
                    userPhotoSon({
                        data: {
                            id: key,
                            ...this.$refs.list.seo_data
                        }
                    }).then(res => {

                        item.children = [
                            ...res.data.user.map((vo) => {
                                
                                return vo;
                            }),
                            ...res.data.list.map((vo) => {
                                vo.children = [];
                                
                                return vo;
                            })

                        ];
                        item.is_req = true;

                        this.$refs.list.set_data('list', list);
                    })
                }
            });
        },
        list_after(res) {
            // this.get_details();
            let list = this.$refs.list.list;
            list = list.map(item => {
                item.children = [];
                
                return item;
            });

        },
    }
};
</script>

<style lang="less">

</style>